import { COLORS, SIZES, FONTS, DEVICE } from "./theme"

const ISENG = false

export {
    COLORS,
    SIZES,
    FONTS,
    DEVICE,
    ISENG,
}
