import {
    Test0Right,
    Test12Right,
    Test34Right,
    Test5Right,
} from "assets/_ru/audio/test"

const finalData = [
    {
        rightAnswers: [0],
        data: {
            title: "Ой, ваше дерево совсем не выросло!",
            label: "Ему нужен уход, внимание и забота!",
            text: "А чтобы получить необходимые для этого знания, советуем ознакомиться с этими разделами:",
            audio: Test0Right,
        },
    },
    {
        rightAnswers: [1, 2],
        data: {
            title: "Вы молодец!",
            label: "Продолжайте в том же духе, и в следующий раз ваше дерево будет совсем здоровым!",
            text: "Для восполнения пробела в знаниях, советуем изучить данные разделы:",
            audio: Test12Right,
        },
    },
    {
        rightAnswers: [3, 4],
        data: {
            title: "Поздравляем!",
            label: "Ваше дерево выглядит совсем неплохо, но ему есть куда расти!",
            text: "Советуем ознакомиться с этими разделами для восполнения недостающих знаний:",
            audio: Test34Right,
        },
    },
    {
        rightAnswers: [5],
        data: {
            title: "Поздравляем!",
            label: "Вы вырастили большое и здоровое дерево!",
            text: "Вы можете продолжить изучение материала в следующем разделе.",
            audio: Test5Right,
        },
    },
]

const testsQsData = {
    1: [
        {
            id: 1,
            title: "Что значат буквы E, S, G в аббревиатуре ESG?",
            correct: 2,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "E – этика, S – социальные аспекты, G – государственное управление",
                },
                {
                    id: 2,
                    text: "E – экология, S – социальные аспекты, G – корпоративное управление",
                },
                {
                    id: 3,
                    text: "E – естественное развитие, S – социальные вопросы, G – управление",
                },
            ],
        },
        {
            id: 2,
            title: "Когда появились первые законы об охране окружающей среды?",
            correct: 1,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "В 60-е",
                },
                {
                    id: 2,
                    text: "В ХIХ веке",
                },
                {
                    id: 3,
                    text: "В 90-х годах ХХ века",
                },
            ],
        },
        {
            id: 3,
            title: "На заводах в Волхове и Балакове мы достигли...",
            correct: 2,
            wrongAnswerSectId: 3,
            items: [
                {
                    id: 1,
                    text: "100% переработки отходов",
                },
                {
                    id: 2,
                    text: "Почти нулевого сброса сточных вод",
                },
                {
                    id: 3,
                    text: "Сокращения выбросов парниковых газов на 30%",
                },
            ],
        },
        {
            id: 4,
            title: "В Компании реализуется проект по охране труда, который называется?",
            correct: 1,
            wrongAnswerSectId: 3,
            items: [
                {
                    id: 1,
                    text: "Трансформация культуры безопасности",
                },
                {
                    id: 2,
                    text: "Модернизация охраны труда",
                },
                {
                    id: 3,
                    text: "Охрана труда",
                },
            ],
        },
        {
            id: 5,
            title: "Компания распространяет требования ESG на поставщиков и потребителей, используя какие инструменты?",
            correct: 2,
            wrongAnswerSectId: 4,
            items: [
                {
                    id: 1,
                    text: "ESG-рейтинги",
                },
                {
                    id: 2,
                    text: "ESG-оценку поставщиков и зеленые стандарты продукции",
                },
                {
                    id: 3,
                    text: "Телефонные конференции",
                },
            ],
        },
    ],
    2: [
        {
            id: 1,
            title: "Сколько целей устойчивого развития ООН принято в 2015 году?",
            correct: 3,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "15",
                },
                {
                    id: 2,
                    text: "18",
                },
                {
                    id: 3,
                    text: "17",
                },
            ],
        },
        {
            id: 2,
            title: "В каком году ФосАгро выделила для себя приоритетные цели устойчивого развития?",
            correct: 3,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "2020",
                },
                {
                    id: 2,
                    text: "2019",
                },
                {
                    id: 3,
                    text: "2018",
                },
            ],
        },
        {
            id: 3,
            title: "Сколько целей устойчивого развития ФосАгро определила как приоритетные?",
            correct: 3,
            wrongAnswerSectId: 3,
            items: [
                {
                    id: 1,
                    text: "17",
                },
                {
                    id: 2,
                    text: "13",
                },
                {
                    id: 3,
                    text: "11",
                },
            ],
        },
        {
            id: 4,
            title: "Является ли компания участником глобального договора ООН?",
            correct: 2,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "Да",
                },
                {
                    id: 2,
                    text: "Да, в статусе «лидера»",
                },
                {
                    id: 3,
                    text: "Нет",
                },
            ],
        },
        {
            id: 5,
            title: "Основная мысль доклада «Пределы роста»",
            correct: 1,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "Ресурсы планеты исчерпаемы, человеку нужно ответственно подходить к их использованию",
                },
                {
                    id: 2,
                    text: "Экология важнее всего",
                },
                {
                    id: 3,
                    text: "Человечество губит окружающую среду",
                },
            ],
        },
    ],
    3: [
        {
            id: 1,
            title: "В чем состоит миссия компании ФосАгро?",
            correct: 1,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "Обеспечение продовольственной безопасности России и всего мира через надежные поставки аграриям безопасных экоэффективных удобрений",
                },
                {
                    id: 2,
                    text: "Создание безопасных удобрений от руды до еды",
                },
                {
                    id: 3,
                    text: "Соблюдение национальных и международных норм",
                },
            ],
        },
        {
            id: 2,
            title: "В состав ФосАгро входит научно-исследовательский институт НИУИФ, какие исследования он проводит?",
            correct: 4,
            wrongAnswerSectId: 3,
            items: [
                {
                    id: 1,
                    text: "Разработка новых марок удобрений",
                },
                {
                    id: 2,
                    text: "Разработка технических решений для производства серной, фосфорной кислот",
                },
                {
                    id: 3,
                    text: "Разработка решений для повышения уровня экологичности производств",
                },
                {
                    id: 4,
                    text: "Все вышеперечисленное",
                },
            ],
        },
        {
            id: 3,
            title: "ФосАгро в рамках работы по внедрению подходов к циркулярной экономике, реализует проект по использованию отходов производства в качестве мелиоранта в сельском хозяйстве и материала для дорожного строительства. Как называется этот материал?",
            correct: 3,
            wrongAnswerSectId: 4,
            items: [
                {
                    id: 1,
                    text: "апатит-нефелиновая руда",
                },
                {
                    id: 2,
                    text: "вскрышная порода",
                },
                {
                    id: 3,
                    text: "фосфогипс",
                },
            ],
        },
        {
            id: 4,
            title: "В 2013 году между ЮНЕСКО, Международным союзом теоретической и прикладной химии (IUPAC) и ФосАгро было подписано соглашение о партнерстве в создании программы грантов для ученых, как она называется?",
            correct: 5,
            wrongAnswerSectId: 7,
            items: [
                {
                    id: 1,
                    text: "Зеленая аптека земледела",
                },
                {
                    id: 2,
                    text: "Химия для жизни на земле",
                },
                {
                    id: 3,
                    text: "Зеленый урожай",
                },
                {
                    id: 4,
                    text: "Зеленые удобрения для роста",
                },
                {
                    id: 5,
                    text: "Зеленая химия для жизни",
                },
            ],
        },
        {
            id: 5,
            title: "Сколько к 2030 году ФосАгро планирует разработать и вывести на рынок марок новых удобрений?",
            correct: 3,
            wrongAnswerSectId: 6,
            items: [
                {
                    id: 1,
                    text: "50",
                },
                {
                    id: 2,
                    text: "20",
                },
                {
                    id: 3,
                    text: "70",
                },
                {
                    id: 4,
                    text: "40",
                },
            ],
        },
    ],
    4: [
        {
            id: 1,
            title: "В каком федеральном проекте ФосАгро является участником?",
            correct: 1,
            wrongAnswerSectId: 4,
            items: [
                {
                    id: 1,
                    text: "Чистый воздух",
                },
                {
                    id: 2,
                    text: "Чистая вода",
                },
                {
                    id: 3,
                    text: "Сохранение лесов",
                },
                {
                    id: 4,
                    text: "Обезвреживание опасных отходов",
                },
            ],
        },
        {
            id: 2,
            title: "В каком документе ФосАгро закреплено такое приоритетное направление как бережное отношение к окружающей среде и сокращение воздействия производства на экосистемы?",
            correct: [2, 3],
            wrongAnswerSectId: "intro",
            items: [
                {
                    id: 1,
                    text: "Положении комитета по устойчивому развитию",
                },
                {
                    id: 2,
                    text: "Политике в области охраны окружающей среды",
                },
                {
                    id: 3,
                    text: "Стратегии-2025",
                },
                {
                    id: 4,
                    text: "Водной стратегии",
                },
            ],
        },
        {
            id: 3,
            title: "Как называется единственный в России сертификат, признанный на международном уровне Всемирной ассоциацией экомаркировки (GEN)?",
            correct: 3,
            wrongAnswerSectId: 7,
            items: [
                {
                    id: 1,
                    text: "«Зеленый стандарт»",
                },
                {
                    id: 2,
                    text: "«Зеленый листок»",
                },
                {
                    id: 3,
                    text: "«Листок жизни»",
                },
                {
                    id: 4,
                    text: "«Зеленый колос»",
                },
                {
                    id: 5,
                    text: "«Зеленый эталон»",
                },
            ],
        },
        {
            id: 4,
            title: "Каких два предприятия ФосАгро работают без стоков?",
            correct: [1, 4],
            wrongAnswerSectId: 5,
            items: [
                {
                    id: 1,
                    text: "Волховский филиал АО «Апатит»",
                },
                {
                    id: 2,
                    text: "АО «Апатит» (Вологодская область)",
                },
                {
                    id: 3,
                    text: "Кировский филиал АО «Апатит»",
                },
                {
                    id: 4,
                    text: "Балаковский филиал АО «Апатит»",
                },
            ],
        },
        {
            id: 5,
            title: "С какими Целями в области устойчивого развития ООН (ЦУР ООН) соотносится экологическая стратегия ФосАгро?",
            correct: 1,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "3, 6, 9, 12, 13, 15, 17",
                },
                {
                    id: 2,
                    text: "3, 12, 17",
                },
                {
                    id: 3,
                    text: "12, 13, 15, 17",
                },
                {
                    id: 4,
                    text: "Ни с какими",
                },
                {
                    id: 5,
                    text: "Со всеми целями в области устойчивого развития ООН",
                },
            ],
        },
    ],
    5: [
        {
            id: 1,
            title: "В какое излучение преобразует Земля энергию солнечного света, исходящее от Земли в космос?",
            correct: 2,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "Радиоволны",
                },
                {
                    id: 2,
                    text: "Инфракрасное",
                },
                {
                    id: 3,
                    text: "Видимый свет",
                },
                {
                    id: 4,
                    text: "Ультрафиолет",
                },
                {
                    id: 5,
                    text: "Ионизирующее",
                },
            ],
        },
        {
            id: 2,
            title: "В каком году и кто впервые обнаружил парниковый эффект, установив, что «земная атмосфера делает планету теплее, чем если бы вместо неё был вакуум»?",
            correct: 3,
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "В 1775 году, Андре Ампер",
                },
                {
                    id: 2,
                    text: "В 1905 году, Карл Андерсон",
                },
                {
                    id: 3,
                    text: "В 1824 году, Жан-Батист Жозеф Фурье",
                },
                {
                    id: 4,
                    text: "В 1824 году, Жозеф-Луи Лагранж",
                },
            ],
        },
        {
            id: 3,
            title: "Выделите основные факторы антропогенного глобального потепления",
            correct: 3,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "массовое животноводство; переработка мусора; сжигание леса",
                },
                {
                    id: 2,
                    text: "вырубка деревьев; массовое животноводство; использование в сельском хозяйстве хлорсодержащих удобрений",
                },
                {
                    id: 3,
                    text: "вырубка деревьев на больших территориях; массовое животноводство; использование в сельском хозяйстве азотсодержащих удобрений",
                },
            ],
        },
        {
            id: 4,
            title: "Сколько градусов составит средняя температура на планете к концу XXI века по предварительным выводам Межправительственной группы экспертов по изменению климата (IPCC, МГЭИК)?",
            correct: 2,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "2 до 4,5 градусов",
                },
                {
                    id: 2,
                    text: "2,5 до 4 градусов",
                },
                {
                    id: 3,
                    text: "3 до 4,5 градусов",
                },
                {
                    id: 4,
                    text: "1,1 до 2 градусов",
                },
            ],
        },
        {
            id: 5,
            title: "На сколько процентов необходимо снизить выбросы парниковых газов к 2028 году по отношению к базовому 2018 году компании ФосАгро?",
            correct: 1,
            wrongAnswerSectId: 5,
            items: [
                {
                    id: 1,
                    text: "14%",
                },
                {
                    id: 2,
                    text: "14,5%",
                },
                {
                    id: 3,
                    text: "20%",
                },
                {
                    id: 4,
                    text: "35%",
                },
            ],
        },
    ],
    6: [
        {
            id: 1,
            title: "Сколько сотрудников работало в ФосАгро в 2021 году?",
            correct: 4,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "Менее 5 тыс. сотрудников",
                },
                {
                    id: 2,
                    text: "Более 1 тыс. сотрудников",
                },
                {
                    id: 3,
                    text: "Менее 50 тыс. сотрудников",
                },
                {
                    id: 4,
                    text: "Более 18 тыс. сотрудников",
                },
            ],
        },
        {
            id: 2,
            title: "Какая программа расширяет контакты со студентами, заинтересованными в работе в Группе ФосАгро?",
            correct: 3,
            wrongAnswerSectId: 2,
            items: [
                {
                    id: 1,
                    text: "«Молодые трудолюбивые специалисты»",
                },
                {
                    id: 2,
                    text: "АНО «ДРОЗД»",
                },
                {
                    id: 3,
                    text: "«Молодые талантливые специалисты»",
                },
                {
                    id: 4,
                    text: "Адресная помощь",
                },
                {
                    id: 5,
                    text: "«Молодые умные студенты»",
                },
            ],
        },
        {
            id: 3,
            title: "Какие документы ФосАгро регламентируют соблюдение прав человека?",
            correct: [3, 4, 5],
            wrongAnswerSectId: 1,
            items: [
                {
                    id: 1,
                    text: "Кодекс корпоративного управления",
                },
                {
                    id: 2,
                    text: "Стратегия-2025",
                },
                {
                    id: 3,
                    text: "Политика управления персоналом",
                },
                {
                    id: 4,
                    text: "Кодекс этики",
                },
                {
                    id: 5,
                    text: "Заявление о прозрачности Компании в отношении Закона Великобритании «О современном рабстве»",
                },
            ],
        },
        {
            id: 4,
            title: "ФосАгро уделяет серьезное внимание безопасности. С каким документом знакомят сотрудников в ходе проведения вводного инструктажа и обучения по охране труда?",
            correct: 1,
            wrongAnswerSectId: 3,
            items: [
                {
                    id: 1,
                    text: "«Золотые правила»",
                },
                {
                    id: 2,
                    text: "«Общественный контроль»",
                },
                {
                    id: 3,
                    text: "Кодекс поведения контрагента",
                },
                {
                    id: 4,
                    text: "«Листок жизни»",
                },
            ],
        },
        {
            id: 5,
            title: "Какая программа ФосАгро существует с 2013 года и готовит высококлассных специалистов для предприятий Компании в рамках образовательной парадигмы «Школа-колледж/вуз-предприятие»?",
            correct: 4,
            wrongAnswerSectId: 4,
            items: [
                {
                    id: 1,
                    text: "АНО «ДРОЗД»",
                },
                {
                    id: 2,
                    text: "Связь поколений",
                },
                {
                    id: 3,
                    text: "Духовное возрождение",
                },
                {
                    id: 4,
                    text: "ФосАгро-школы",
                },
                {
                    id: 5,
                    text: "Наши любимые города",
                },
            ],
        },
    ],
}

export default { finalData, testsQsData }
