import React from "react"
import styled from "styled-components"
import { COLORS } from "constants"

export default function UnMute({ color = COLORS.white }) {
    return (
        <Wrapper
          width="29"
          height="22"
          viewBox="0 0 29 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
            <Path
                d="M3.13476 15.8235L7.76555 15.8235L15.3264 21.3707C16.1239 21.9171 16.7055 21.1504 16.7496 20.6834L16.7496 0.88246C16.7804 0.358137 16.0446 -0.346834 15.2823 0.195112L7.71709 5.6939L3.13477 5.6939C1.39436 5.6939 0.0196679 7.11706 0.0196678 8.85746L0.0196674 12.6599C0.0152613 14.4047 1.43842 15.8235 3.13476 15.8235ZM14.9166 18.8945L8.91114 14.5325L8.91114 7.07741L14.9166 2.67133L14.9166 18.8945ZM1.76007 8.90152C1.76007 8.12165 2.31083 7.52683 3.04224 7.52683L7.07821 7.52683L7.07821 14.0831L3.04664 14.0831C2.35929 14.0831 1.76447 13.4882 1.76447 12.7084L1.76447 8.90152L1.76007 8.90152Z"
                fill={color}
            />
            <Path
                d="M23.5833 10.06L24.5553 8.422C24.8193 7.99 25.0833 7.696 25.3473 7.54C25.6113 7.384 25.9413 7.306 26.3373 7.306C26.7093 7.306 27.1353 7.408 27.6153 7.612L24.8793 11.77L27.5073 15.964C27.1473 16.108 26.7693 16.18 26.3733 16.18C25.8933 16.18 25.5153 16.108 25.2393 15.964C24.9753 15.82 24.7353 15.574 24.5193 15.226L23.4393 13.48L21.9633 16H19.3893L22.1253 11.68L19.6053 7.558C20.1453 7.378 20.6373 7.288 21.0813 7.288C21.4413 7.288 21.7413 7.366 21.9813 7.522C22.2333 7.678 22.4553 7.936 22.6473 8.296L23.5833 10.06Z"
                fill={color}
            />
            <Rect
                width="21.5595"
                height="21.5595"
                fill={color}
                transform="translate(21.5596 21.5596) rotate(-180)"
            />
        </Wrapper>
    )
}
const Path = styled.path`
  transition: all 0.3;
`

const Rect = styled.path`
  transition: all 0.3;
`

const Wrapper = styled.svg``

