import React, { useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"

import { COLORS } from "constants"
import { ModalStore } from "store"

import SendButton from "../SendButton"
import { Modal } from "./base"

function MobNotifModal() {
    const closeModal = () => {
        ModalStore.closeModal("mobNotif")
        if (ModalStore.showCookieLater) {
            ModalStore.showModal("cookie")
            ModalStore.setShowCookieLater(false)
        }

        if (ModalStore.showWelcomeBackLater) {
            ModalStore.showModal("welcomeBack")
            ModalStore.setShowCookieLater(false)
        }
    }

    const handleResize = () => {
        if (
            document.documentElement.clientWidth > 1024 &&
            ModalStore.isVisible.mobNotif
        ) {
            ModalStore.closeModal("mobNotif")
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
    })

    return (
        <StyledModal
            isOpen={ModalStore.isVisible.mobNotif}
            onClose={closeModal}
            noCloseBtn
        >
            <Container>
                <Content>
                    <Text>
                        Для более комфортного изучения курса рекомендуем
                        воспользоваться персональным компьютером.
                    </Text>
                    <StyledSendButton text="Ок" onClick={closeModal} />
                </Content>
            </Container>
        </StyledModal>
    )
}

export default observer(MobNotifModal)

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
`

const StyledSendButton = styled(SendButton)`
    font-size: 16px;
`

const StyledModal = styled(Modal)`
    .modal-window {
        width: 92%;
        max-width: 340px;
        height: auto;

        padding: 40px 20px;
        border-radius: 20px;
        background-color: rgba(255, 255, 255, 0.8);
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Text = styled.div`
    margin-bottom: 30px;

    font-family: Calibri, sans-serif;
    font-size: 16px;
    line-height: 1.3;
    color: ${COLORS.black};
    text-align: center;
`
