import React, { useEffect, useRef, useState } from "react"
import styled, { css, keyframes } from "styled-components"
import { COLORS } from "constants"
import { showContent } from "constants/animations"
import { CourseProgressStore } from "store"

export default function Lamp({ className, makeAnim = false, isIntro = false }) {

    const intervalId = useRef(null)
    const containerRef = useRef(null)
    const [key, setKey] = useState(1)
    const [dontAnimate, setDontAnimate] = useState(true)

    useEffect(() => {
        intervalId.current = setInterval(() => {
            setKey((keyVal) => keyVal + 1)
            setDontAnimate(false)
        }, 5200);

        return () => {
            clearInterval(intervalId.current)
        }
    }, [])

    return (
        <Container
            width="53"
            className={`${dontAnimate ? 'no-anim' : ""} ${className || ''}`}
            makeAnim={makeAnim}
            color={CourseProgressStore.activeSectColor}
            isIntro={isIntro}
            ref={containerRef}
            key={key}
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.5004 11.9746C18.9401 11.9746 12.7891 18.1256 12.7891 25.686C12.7891 28.7801 13.8036 31.7036 15.7217 34.1391C16.1003 34.6212 16.4981 35.0826 16.9193 35.5712L16.9424 35.598C18.231 37.093 19.5633 38.6394 20.0608 40.4602C20.1733 40.8775 20.1893 41.6775 20.1828 42.2938V43.3412C20.1828 44.2998 20.9599 45.0769 21.9185 45.0769H31.0069C31.9655 45.0769 32.7426 44.2998 32.7426 43.3412V42.2815C32.7368 41.4766 32.7784 40.8893 32.8625 40.5845C33.3628 38.7757 34.8393 37.0389 36.1537 35.493L36.2104 35.4263C36.5897 34.9806 36.9482 34.5596 37.2745 34.1447C39.1953 31.7078 40.2106 28.7829 40.2106 25.686C40.2104 18.1256 34.0601 11.9746 26.5004 11.9746ZM34.5467 31.9975C34.2609 32.3611 33.924 32.7571 33.5671 33.1762L33.4972 33.2583C31.9618 35.0644 30.2215 37.1113 29.5161 39.6611C29.4163 40.0231 29.3055 40.5646 29.2772 41.6055H23.6509C23.6268 40.5 23.5141 39.933 23.4111 39.5512C22.7053 36.9676 20.968 34.9517 19.5718 33.3315L19.5485 33.3042C19.1539 32.8466 18.7813 32.4143 18.4501 31.9931C17.0176 30.1742 16.2604 27.993 16.2604 25.686C16.2604 20.0397 20.8541 15.446 26.5004 15.446C32.146 15.446 36.739 20.0397 36.739 25.686C36.739 27.9956 35.9813 30.1774 34.5467 31.9975Z"
                fill="#00529B"
            />
            <path
                d="M31.0075 45.5693H21.9193C20.9607 45.5693 20.1836 46.3465 20.1836 47.305C20.1836 48.2636 20.9607 49.0407 21.9193 49.0407H31.0077C31.9663 49.0407 32.7434 48.2636 32.7434 47.305C32.7434 46.3465 31.966 45.5693 31.0075 45.5693Z"
                fill="#00529B"
            />
            <path
                d="M28.7347 49.5288H24.1907C23.2322 49.5288 22.4551 50.3059 22.4551 51.2645C22.4551 52.223 23.2322 53.0002 24.1907 53.0002H28.7347C29.6933 53.0002 30.4704 52.223 30.4704 51.2645C30.4704 50.3059 29.6933 49.5288 28.7347 49.5288Z"
                fill="#00529B"
            />
            <Line
                d="M50.4498 23.9502H44.4622C43.5037 23.9502 42.7266 24.7273 42.7266 25.6859C42.7266 26.6444 43.5037 27.4215 44.4622 27.4215H50.4498C51.4084 27.4215 52.1855 26.6444 52.1855 25.6859C52.1855 24.7273 51.4084 23.9502 50.4498 23.9502Z"
                className="line-6"
            />
            <Line
                d="M10.2741 25.6859C10.2741 24.7273 9.49698 23.9502 8.53842 23.9502H2.55012C1.59157 23.9502 0.814453 24.7273 0.814453 25.6859C0.814453 26.6444 1.59157 27.4215 2.55012 27.4215H8.53842C9.49698 27.4215 10.2741 26.6444 10.2741 25.6859Z"
                className="line-2"
            />
            <Line
                d="M26.5013 9.4608C27.4598 9.4608 28.237 8.68368 28.237 7.72513V1.73567C28.237 0.777118 27.4598 0 26.5013 0C25.5427 0 24.7656 0.777118 24.7656 1.73567V7.72513C24.7656 8.68368 25.5427 9.4608 26.5013 9.4608Z"
                className="line-4"
            />
            <Line
                d="M40.428 37.1594C39.7502 36.4815 38.6514 36.4813 37.9733 37.1591C37.2955 37.837 37.2953 38.9358 37.9731 39.6138L42.2072 43.8491C42.5463 44.1881 42.9904 44.3576 43.4347 44.3576C43.8788 44.3576 44.3229 44.1881 44.6619 43.8493C45.3398 43.1715 45.34 42.0727 44.6622 41.3946L40.428 37.1594Z"
                className="line-7"
            />
            <Line
                d="M12.5707 14.2114C12.9098 14.5504 13.3539 14.72 13.7982 14.72C14.2423 14.72 14.6864 14.5506 15.0252 14.2118C15.7033 13.534 15.7033 12.4352 15.0257 11.7574L10.7932 7.52326C10.1153 6.84519 9.01653 6.84519 8.33869 7.5228C7.66062 8.20063 7.66062 9.29943 8.33823 9.97727L12.5707 14.2114Z"
                className="line-3"
            />
            <Line
                d="M12.5727 37.1593L8.33834 41.3946C7.6605 42.0724 7.66073 43.1714 8.33857 43.849C8.67737 44.1878 9.12171 44.3572 9.56581 44.3572C10.0099 44.3572 10.4542 44.1878 10.7933 43.8488L15.0276 39.6135C15.7055 38.9357 15.7052 37.8367 15.0274 37.1591C14.3495 36.4814 13.2505 36.4814 12.5727 37.1593Z"
                className="line-1"
            />
            <Line
                d="M39.2023 14.7198C39.6464 14.7198 40.0907 14.5504 40.4298 14.2113L44.6629 9.97747C45.3408 9.29963 45.3406 8.2006 44.6627 7.523C43.9849 6.84516 42.8859 6.84516 42.2082 7.52323L37.9751 11.7571C37.2972 12.4349 37.2975 13.534 37.9753 14.2116C38.3139 14.5506 38.758 14.7198 39.2023 14.7198Z"
                className="line-5"
            />
        </Container>
    )
}

const Line = styled.path``

const scale = keyframes`
    0% {
        transform: none;
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: none;
    }
`

const Container = styled.svg`
    ${({ makeAnim }) =>
        makeAnim &&
        css`
            animation: ${scale} 0.7s ease-in;
            animation-delay: 1.1s;

            ${Line} {
                animation: ${showContent} 0.15s both;

                &.line-1 {
                    animation-delay: 0.15s;
                }

                &.line-2 {
                    animation-delay: 0.3s;
                }

                &.line-3 {
                    animation-delay: 0.45s;
                }

                &.line-4 {
                    animation-delay: 0.6s;
                }

                &.line-5 {
                    animation-delay: 0.75s;
                }

                &.line-6 {
                    animation-delay: 0.9s;
                }

                &.line-7 {
                    animation-delay: 1.05s;
                }
            }

            &.no-anim {
                animation: none;

                ${Line} {
                    animation: none;
                }
            }
        `}

    ${Line} {
        fill: ${({color, isIntro}) => isIntro ? COLORS.orange : color };
    }
`
