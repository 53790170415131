import { makeAutoObservable } from "mobx"
import {
    TestStart,
    Test0Right,
    Test12Right,
    Test34Right,
    Test5Right,
    Instruction1,
    Instruction2,
    FinalAudio,
} from "../assets/audio"
import CourseTestStore from "./courseTest"

// !ВАЖНОЕ ПРИМЕЧАНИЕ
// Была проблема со звуком на айфоне - он не всегда проигрывался автоматически при переключении
// между страницами курса, то же самое было с видео
// (кажется потому что на айфоне другие, более строгие правила для проигрывания медиа
// там можно производить медиа только при действии пользователя (т е каждый звук должен быть инициирован
// пользователем, а не как обычно - доступно проигрывание медиа в любой момент после первого взаимодействия пользователя
// со страницей))
// тут об этом
// https://www.reddit.com/r/webdev/comments/71nkym/safari_11_has_a_major_change_to_web_audio_api/
// https://webkit.org/blog/7734/auto-play-policy-changes-for-macos/

// Чтобы решить эту проблему, я создаю элементы с аудио в тот момент, когда происходит клик, и произвожу позже
// именно их. При таком подходе звук на айфоне в основном проигрывается
// (я опиралась на эту статью
// https://arrangeactassert.com/posts/how-to-fix-the-request-is-not-allowed-by-the-user-agent-or-the-platform-in-the-current-context-possibly-because-the-user-denied-permission/
// )
// (однако звук вроде так все равно не всегда проигрывается, возможно на более старых айфонах это решение не работает
// я проверяла в эмуляторе на айфонах 16, 12 было ок)

// ! Это очень плохое решение
// мне пришлось написать кучу кода для этого, и это значительное усложнение, которое возможно не стоит того
// было бы хорошо (или даже необходимо) найти другое решение, если будет возможность и время
// можно попробовать с этого, то что про audiobuffer написано
// https://www.reddit.com/r/webdev/comments/71nkym/safari_11_has_a_major_change_to_web_audio_api/

class Sound {
    play = {
        isPlayingUser: false,
        isPlayingSound: false,
    }

    titleSound = {
        title: false,
        corse1: false,
        corse2: false,
        corse3: false,
        corse4: false,
        corse5: false,
        corse6: false,
    }

    newSectAudio = null

    testStartAudio = null

    testFinalAudio = null

    finalAudio = null

    finalTestAudios = {}

    introAudioEls = []

    instructionAudioEls = [null, null]

    contentAudioEl = null

    contentVideoEl = null

    makeVideoPlayerOutEl = false

    makeAudioPlayerOutEl = false

    constructor() {
        makeAutoObservable(this)
    }

    init() {
        this.setInstructionAudioEls()
        this.setTestStartAudio()
        this.setTestFinalsAudio()
        this.setFinalAudio()
    }

    getIsPlaying() {
        return this.play.isPlayingUser && this.play.isPlayingSound
    }

    setIsPlayingUser(value) {
        this.play.isPlayingUser = value
    }

    setContentAudioEl(el) {
        this.contentAudioEl = el
    }

    setContentVideoEl(el) {
        this.contentVideoEl = el
    }

    setIsPlayingSound(value) {
        this.play.isPlayingSound = value
    }

    getPlayedTitleSound(key) {
        return this.titleSound[key]
    }

    setPlayedTitleSound(key, value) {
        this.titleSound[key] = value
    }

    setNewSectAudio(value) {
        this.newSectAudio = value
    }

    setIntroAudioEls(els) {
        this.introAudioEls = els
    }

    resetNewSectAudio() {
        this.newSectAudio = null
    }

    resetContentAudioEl() {
        this.contentAudioEl = null
    }

    resetContentVideoEl() {
        this.contentVideoEl = null
    }

    resetIntroAudios() {
        this.introAudioEls = []
    }

    setTestStartAudio() {
        this.testStartAudio = new Audio(TestStart)
    }

    setTestFinalAudio() {
        this.testFinalAudio =
            this.finalTestAudios[CourseTestStore.rightAnswersCount]
    }

    resetTestFinalAudio() {
        if (this.testFinalAudio) {
            this.testFinalAudio.pause()
            this.testFinalAudio = null
        }
    }

    setTestFinalsAudio() {
        const audio0 = new Audio(Test0Right)
        const audio5 = new Audio(Test5Right)
        const audio12 = new Audio(Test12Right)
        const audio34 = new Audio(Test34Right)

        this.finalTestAudios = {
            0: audio0,
            1: audio12,
            2: audio12,
            3: audio34,
            4: audio34,
            5: audio5,
        }
    }

    resetInstructionEls() {
        this.instructionAudioEls = []
    }

    restartInstructionEls() {
        if (this.instructionAudioEls) {
            this.instructionAudioEls.forEach((el) => {
                // eslint-disable-next-line no-param-reassign
                el.currentTime = 0
            })
        }
    }

    restartFinalAudio() {
        if (this.finalAudio) {
            this.finalAudio.pause()
            this.finalAudio.currentTime = 0
        }
    }

    setFinalAudio() {
        this.finalAudio = new Audio(FinalAudio)
    }

    setInstructionAudioEls() {
        this.instructionAudioEls = [
            new Audio(Instruction1),
            new Audio(Instruction2),
        ]
    }

    setMakeAudioPlayerOutEl(val) {
        this.makeAudioPlayerOutEl = val
    }

    setMakeVideoPlayerOutEl(val) {
        this.makeVideoPlayerOutEl = val
    }
}

export default new Sound()
